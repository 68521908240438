import React from 'react'
import Adventurebody from './Adventurecomponent/Adventurebody'
import Whyus from '../IndexPage/Indexcomponent/Whyus'
// import Nearbylocation from '../Locationlisting/Listingcomponent/Nearbylocation'
import Featured from '../IndexPage/Indexcomponent/Featured'
import { useGetThingsQuery } from '../Apihandlers/apidata';
import Loader from '../Components/Loader';
import Meta from '../Components/Meta';
import Mainloader from '../Components/Mainloder';
function Adventure(props) {
const url = props.slugUrl;

const {data:thingDatas, isError:thingsError, isLoading: thingsLoad} = useGetThingsQuery();
if(thingsError){
  return <p>Error loading data...</p>
}
if (thingsLoad) {
  return <div> <Loader/></div>; 
}

let thData = [];
if(thingDatas){
  thData = thingDatas.filter((itm)=>itm.things.things_url == url);
}

const bnrimg = thData.map(i=>i.banner_image)

const metaTags = thData.map((itm)=>{
  return <Meta ogtittle={itm.things.OgMetaTitle} ogdesc={itm.things.OgMetaDescription} title={itm.things.metaTitle} keywords={itm.things.metaKeyWords} description={itm.things.metaDescription} canonical={'https://www.kanatalresorts.in/'+itm.things.things_url} noIndex="false"/>
 });

  return (
    <>
      {metaTags}
    <div style={{backgroundImage : `url(${bnrimg})`, backgroundAttachment:'fixed', backgroundSize:'cover'}} >
        <Adventurebody thDatas={thData} bnrimgs={bnrimg}/>

        <div style={{backgroundColor:'rgb(0 0 0 / 88%'}}>
       {/* <Nearbylocation/> */}
       <Featured />
       </div>
       <div style={{backgroundImage: 'linear-gradient(#000000e0  70%, #fff'
}} >
       <Whyus/>
        </div> 
    </div>
  
    </>
  )
}

export default Adventure

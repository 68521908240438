import React from 'react'

import './Listingcss/Nearbylocation.css'
import Loader from '../../Components/Loader';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useGetNearByLocationQuery } from '../../Apihandlers/apidata';
// import required modules
import {Autoplay, Navigation} from 'swiper/modules';

function Nearbylocation(props) {

  const {data:adata, isError: aError, isLoading: aLoading} = useGetNearByLocationQuery();
  const lokid = props.lokdta;
  if(aError){
    return <p>Error loading data...</p>
  }
  if (aLoading) {
    return   <Loader/>; 
  }

  let atrctiondata = [];
  if (!adata) {
    atrctiondata = adata.filter((atr) => lokid.includes(atr.location_id));
  }else{
    atrctiondata = adata;
  }

  return (
    <>
    <section className='near-by-section'>
      <h2 style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >Nearby Attraction</h2>
      <h3>Enjoy a quick escape to the most fascinating places & get a whiff of the history in hills.</h3>
        <div className='near-by-container'>
        <Swiper
            
            // spaceBetween={50}
            loop={true}
            // navigation={true}
            autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}   
          breakpoints={{
            500: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
           
            988: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1260: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          
            modules={[Autoplay, Navigation]}
            className="mySwiper near-by-slider"
          >
            {
              atrctiondata.map((itm, key)=>(
                itm.status == 1 ?
                <SwiperSlide className='near-by-slide' style={{backgroundImage:`url(${itm.vertical_image})`}} >
                    {/* <img className='border-image1' src='../icons/temple.png' alt='kanatal resorts'/>
                    <img className='border-image2' src='../icons/waterfall.png' alt='kanatal resorts'/> */}
                    <p>{itm.heading}</p>
                </SwiperSlide>
                :null
              ))
            }
                  


            </Swiper>
        </div>
    </section>
    </>
  )
}

export default Nearbylocation

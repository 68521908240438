import React from 'react'
import './indexcss/Iconsadventure.css'
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader'
import { useGetThingsQuery } from '../../Apihandlers/apidata';
function Iconadventure() {
  const { data: thingsData, error: thingsError, isLoading: thingsLoading } = useGetThingsQuery();
  
  if(thingsLoading){
    return <p></p>
  }
  if(thingsError){
    return <p>Error fetching data</p>
  }
  console.log("myname", thingsData)
  return (
    <>
        <div className='adventure-icons-section'>
            {
              thingsData.map((itm, key)=>(
                  <a href={itm.things.things_url} key={key} className='adventure-icon'><img src={itm.things.iconOne} alt={'Advanture Service in '+ itm.things.things} /><p>{itm.things.things}</p></a>       
              ))
            }          
        </div>
        
    </>
  )
}

export default Iconadventure
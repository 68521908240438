import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apidata } from "./apidata";
export const store = configureStore({
    reducer: {
      [apidata.reducerPath]: apidata.reducer,
    },
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware().concat(apidata.middleware)
  })
  
  setupListeners(store.dispatch)
import React from 'react'
import Indextestandform from '../IndexPage/Indexcomponent/Indextestandform'
import './contact.css';
import Mainloader from '../Components/Mainloder';
function Contact() {
  return (
    <div style={{backgroundImage : 'linear-gradient(rgb(0, 0, 0) 55%,  rgb(255 255 255)100%)'}} >
        <div className='contact-banner' >
            <img src='images/contact.jpg' alt='kanatal resorts contact'/>
            <h2>Contact Us</h2>
        </div>
      <Indextestandform/>
     
    </div>
  )
}

export default Contact

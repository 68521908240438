import React from 'react'
import './css/Loader.css'

function Loader() {
  return (
    <div className="loader">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
  )
}

export default Loader

import React, { useState } from 'react'
import './Detailcomponentcss/Detailbanner.css'
import {Link} from 'react-router-dom'
import { IoIosStar } from "react-icons/io";
import { useGetLocsQuery } from '../../Apihandlers/apidata';
import Loader from '../../Components/Loader';
function Detailbanner(props) {

const { rating } = props.pkdta;
const stars = Array.from({ length: rating }, (_, index) => (
    <IoIosStar key={index} className='star-rating' />
));

const loksId = props.pkdta.location_id;

const {data:loksdta, isError: plError, isLoading: plLoading} = useGetLocsQuery();
if(plError){
  return <div>Error loading data...</div>; 
}
if(plLoading){
  return <div><Loader/></div>;
}

let loksData = [];
if (loksdta) {
  loksData = loksdta.filter((lok) => lok.location.id == loksId);
}
const priceDisplay = props.pkdta.price === null ? 'Call for price' : props.pkdta.price +"/-**" +`<span>${props.pkdta.nightDay ?? ''}</span>`  ?? 'Call for price';

  return (
    <>
      <section className='detail-banner-section'>
        <img src={props.pkBanner.map(b=>b.banner)} alt='kanatal resorts'/>
        <h1>{props.pkdta.heading}</h1>

        <div className='detail-head-container' >
          
            <div className='detail-head-box'>
              <div className='detail-head-box-text'>
                  <div className='detail-head-inner'>
                      <img src='icons/wallet.png' alt='kanatal resorts'/>
                      <div className=''>
                          <p>Price</p>
                          <h6 className='pricefirst' dangerouslySetInnerHTML={{__html:priceDisplay}}></h6>
                         
                      </div>
                  </div>
                  <div className='detail-head-inner'>
                      <img src='icons/location-detail.png' alt='kanatal resorts'/>
                      <div className=''>
                          <p>Destination</p>
                          <h6>{loksData.map((a)=>a.location.location)}</h6>
                      </div>
                  </div>
                  { props.pkdta.rating == null ? '' : <div className='detail-head-inner'>
                      <img src='icons/star.png' alt='kanatal resorts'/>
                      <div className=''>
                          <p>Rating</p>
                          <h6>
                            {stars}
                          </h6>
                      </div>
                  </div>}
                </div>
                
                {/* <div className='detail-head-box-link'>
                  <Link to="." className='popupbutton'>Book this Hotel
                  <div className='hangingkey'> <img src='../icons/hangingkey.gif'/> </div>
                  </Link>
                  
                </div> */}
            </div>
        </div>
      </section>
    </>
  )
}

export default Detailbanner

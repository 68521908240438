import React, { useState } from 'react'
import './Detailcomponentcss/Detailnearby.css'
import { useGetNearByLocationQuery } from '../../Apihandlers/apidata';
import Loader from '../../Components/Loader'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import {Autoplay, Navigation} from 'swiper/modules';

function Detailnearby(props) {

  const lokId = props.lokId;
  
  const {data: nrbyAdata, isError: nrbyAError, isLoading: nrbyALoading} = useGetNearByLocationQuery();
  if(nrbyAError){
    return <div>Error loading data...</div>; 
  }
  if(nrbyALoading){
    return <div> <Loader/></div>;
  }
  
  let nearbyData = [];
  if (nrbyAdata) {
    nearbyData = nrbyAdata.filter((pkg) => lokId == pkg.location_id);
  }
  
  if(nearbyData.length != 0) {
  return (
    <>
 <section className='near-by-detail-section'>
      <h2 style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >Nearby Attraction</h2>
      <h3>Enjoy a quick escape to the most fascinating places & get a whiff of the history in hills.</h3>
        <div className='near-by-detail-container'>
        <Swiper
            slidesPerView='1'
            speed={3000}
            spaceBetween={20}
            loop={true}
            centeredSlides={true}
            autoplay={{
            delay: 3500,
            
          }}   
            modules={[Autoplay, Navigation]}
            className="mySwiper near-by-detailswiper"
          >
          {
            nearbyData.map((itm, key) => (
              itm.status == 1 ?
              <SwiperSlide key={key} className='near-by-detail-slide' style={{backgroundImage: `url(${itm.vertical_image})`}}>
                <p>{itm.heading}</p>
              </SwiperSlide>
              : null
            ))
          }

          
            </Swiper>
        </div>
    </section>
    </>
  )

}
}

export default Detailnearby

import React from 'react'
import { Link } from 'react-router-dom';
import './indexcss/Location1-featured.css'
import Loader from '../../Components/Loader'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoIosStar } from "react-icons/io";
import { ImLocation } from "react-icons/im";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useGetLocsQuery } from '../../Apihandlers/apidata';
// import required modules
import {Autoplay, Navigation} from 'swiper/modules';
import { useGetPackageQuery } from '../../Apihandlers/apidata';
const Location1featured= () => {

  const {data:kntData, isError: kntError, isLoading: kntLoading} = useGetPackageQuery();
  if (kntError) {
      return <div>Error loading data...</div>;
    }
  
    if (kntLoading) {
      return <div>Loading...</div>;
    }

    let kantaPackage = []; 
    let id = 1;
    if (kntData) {
      kantaPackage = kntData.filter((pkg) => pkg.resort_camp_data.location_id == id);
    }
    
   console.log(kantaPackage)
      
  
      

  return (
    <>
      <section className='location1featured-section'>
        <div className='featured-property-conatiner'>

            <Swiper
            spaceBetween={20}
            loop={true}
            navigation={true}
            autoplay={{
            delay: 3500,
            disableOnInteraction: false,
            reverseDirection: true,
          }}   
          breakpoints={{
            1200: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          
            900: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            540: {
              slidesPerView: 1,
             
            },
           
            
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper location1-featured"
          >
               {
                kantaPackage.map((itm, key)=>(
                  itm.resort_camp_data.status === 1 ?
                    <SwiperSlide >
                        <div className='featured-property'>
                            <div className='property-image'>
                                <img src={itm.banner_image.map((b)=>b.banner)} alt={itm.resort_camp_data.heading}></img> 
                                <div className='rating' style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}>
                                    {Array.from({ length: itm.resort_camp_data.rating }, (_, index) => (
                                        <IoIosStar key={index} className='star-rating' />
                                    ))}
                                </div>    
                            </div>
                            <div className='property-text'>
                            <div className='topline'>
                              <h4 
                              style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}
                              >{itm.resort_camp_data.heading} </h4>
                              <span className='lkone'><ImLocation /> Kanatal</span>
                              </div>
                                <p>{itm.resort_camp_data.small_description}</p>
                                <div className='price-link'>
                                <div className='price-feature'><h5>{itm.resort_camp_data.price ? `INR ${itm.resort_camp_data.price}/-**` : 'Call for Price'}</h5> <span>{itm.resort_camp_data.nightDay ?? ''}</span> </div> 
                                    <a href={itm.resort_camp_data.package_url}>View More</a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    : null
                ))
            }

            
          </Swiper>

        </div>

        <div className='featured-text'>
            {/* <p>Discover Wild </p> */}
            <h2 style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >Discover Wild Adventure in Kanatal</h2>
            <h3>Thrill yourself with spell-binding adventures of Kanatal availing best offers for Luxury Resorts on your Weekend Getaways.</h3>
            <a href="./Kanatal-7">View All</a>
        </div>

    </section>
      
    </>
  )
}

export default Location1featured

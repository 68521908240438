import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import './indexcss/Indextestandform.css'
import { usePostEnqsMutation } from '../../Apihandlers/apidata';
function Indexform() {
    const [postEnqs, { isLoading, isError, isSuccess }] = usePostEnqsMutation();
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    
    
    


    const [formData, setFormData]=useState({
      name: '',
      mobile: '',
      email: '',
      check_in: '',
      check_out: '',
      adult: '',
      kids: '',
      message: '',
    });

   

const handleChange = (e)=>{
    setFormData({...formData, [e.target.name]: e.target.value});
}

// Handle form submission
const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage();
    setErrors({});

    try {
      await postEnqs(formData).unwrap();
      
      setMessage('Your message has been sent successfully!');
      setFormData({
        name: '',
        mobile: '',
        email: '',
        check_in: '',
        check_out: '',
        adult: '',
        kids: '',
        message: '',
      });
    } catch (error) {
        setMessage(error.data.msg);
        setErrors(error);
        console.error('Error submitting form:', error);
      }
  };
 
  return (
    <>

    <section className='test-form-section'>

{/* testimonial */}

<div className='testimonial-section'>
  <div className='test-text'>
    <p>What</p>
    <h2>They Say</h2>
  </div>

<Swiper
            slidesPerView= {1}
            spaceBetween={40}
            loop={true}
            speed={1300}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
           
          modules={[Autoplay , Pagination]}
          className="mySwiper testimonial"
        >
          <SwiperSlide> <div className='testimonial-slide'>
            <p>One of our common friends suggested us for CYJ and we planned to opt for the same and went to Mussoorie. They made our stay happy and beautiful with their impeccable services.</p>
            <h4>Krishna Sharma</h4>
          </div>
          </SwiperSlide>
          <SwiperSlide> <div className='testimonial-slide'>
            <p>I went to Kanatal last year with my friends and hands down! Booking a tour package from CYJ was the best thing that I could do! I would highly recommend everyone opt for CYJ.</p>
            <h4>Aman Madaan</h4>
          </div>
          </SwiperSlide>
          <SwiperSlide> <div className='testimonial-slide'>
            <p>We had been to Rishikesh last month with my family and had rafting package with CYJ and hopefully it was my best decision as they made it a hassle-free experience.</p>
            <h4>Uttam Yadav</h4>
          </div>
          </SwiperSlide>
          
   </Swiper>
</div>


{/* form start */}

<div class="index-form">

        {/* <p>Fill the Form to Send your Request</p> */}
       

    <div class="star"></div>
    {/* <div class="star"></div> */}
    <div class="star"></div>
    {/* <div class="star"></div> */}
    <div class="star"></div>

  
    <div class="contact-form">
    <h2>Get in Touch</h2>
       <form onSubmit={handleSubmit} >
       <img src='./images/formimage.png' className='form-image' alt='kanatal resorts'></img>
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        value={formData.name} onChange={handleChange}
      />
      <span style={{color:'red', fontSize:'.8rem',}}>{errors.data ? errors.data.name : null}</span>
      <input
        type="email"
        name="email"
        placeholder="Your Email"
        value={formData.email} onChange={handleChange}
      />
      <span style={{color:'red', fontSize:'.8rem',}}>{errors.data ? errors.data.email : null}</span>
      <input
        type="number"
        name="mobile"
        placeholder="Your Number"
        value={formData.mobile} onChange={handleChange}
      />
      <span style={{color:'red', fontSize:'.8rem',}}>{errors.data ? errors.data.mobile : null}</span>
      {/* <div className="check-in-out">
        <div style={{width:'100%'}}>
        <label htmlFor="check_in">Check-In:</label>
        <input
          type="date"
          id="check_in"
          name="check_in"
          value={formData.check_in} onChange={handleChange}
        /></div>
         <div style={{width:'100%'}}>
        <label htmlFor="check_out">Check-Out:</label>
        <input
          type="date"
          id="check_out"
          name="check_out"
          value={formData.check_out} onChange={handleChange}
        /></div>
      </div>
      <div className="form-adult-child">
        <label htmlFor="adults">
          Number of Adults:
          <br />
          <span>12+ Years</span>
        </label>
        <div style={{display:'flex', width:'30%'}}>
        <button type="button" onClick={dicrementCountad}>-</button>
          <input
            type="number"
            id="adults"
            name="adult"
            min={1}
            max={10}
            value={formData.adult} onChange={handleChange}
            
          />
         <button type="button" onClick={incrementCountad}>+</button>
        </div>
      </div >
      <div className="form-adult-child">
        <label htmlFor="children">
          Number of Kids:
          <br />
          <span>6 - 12 Years</span>
        </label>
        <div style={{display:'flex', width:'30%'}}>
        <button type="button" onClick={dicrementCountch}>-</button>
          <input
            type="number"
            id="children"
            name="kids"
            min={0}
            max={10}
            value={formData.kids} onChange={handleChange}
           
          />
          <button type="button" onClick={incrementCountch}>+</button>
        </div>
      </div> */}
      <textarea
        name="message"
        placeholder="Message"
        value={formData.message} onChange={handleChange}
      />

      <button
              type="submit"
              id="submit"
              name="submit"
              className="form-btn semibold"
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send Enquiry'}
          </button>
          {message && <div className={`alert ${isSuccess ? 'success' : 'error'}`}>{message}</div>}
    </form>
        
     
      
    </div>

</div>

</section>

    </>
  )
}

export default Indexform

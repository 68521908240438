import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.css'
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useGetLocsQuery} from '../../Apihandlers/apidata';
import Loader from '../Loader'

const Footer = () => {

  const { data, isError, isLoading } = useGetLocsQuery();

  return (
    <>
    <section className='footer-section'style={{backgroundImage:'url(../images/footerbgnew.png)'}} >

      <div className="clouds">
              <img src="../icons/cloud1.png"  className='cloud1' alt='kanatal resorts'/>
              <img src="../icons/cloud2.png"  className='cloud2' alt='kanatal resorts'/>
              <img src="../icons/cloud3.png"  className='cloud3' alt='kanatal resorts'/>
              <img src="../icons/cloud4.png"  className='cloud4' alt='kanatal resorts'/>
              <img src="../icons/cloud5.png"  className='cloud5' alt='kanatal resorts'/>

          <div className='footer-logo'>
            <div className='footer-logo-image'><img src='../logo/cyj-color-logo.png' alt='kanatal resorts'></img></div>
            <div><p>CYJ offers a wide range of services, including Day Outings, Corporate Event Planning Services, Destination Weddings, and Resort Packages near Delhi.</p></div>
          </div>
      </div>

      <div className='footer-inner'> 
      <div className='footer-container'>
      <div className='footer-info'>
        <h3>Useful Links</h3>
          <ul> 
            <li><a href='https://www.raftingcamps.in/' aria-label="Rafting Camps" target='blank'> <IoIosArrowDroprightCircle /> Rafting In Rishikesh</a></li>
            <li><a href='https://www.corbettresort.in/' aria-label="Corbettresorts" target='blank'> <IoIosArrowDroprightCircle /> Jim Corbett Resorts</a></li>
            <li><a href='https://www.theterracesresorts.com/' aria-label="theterracesresorts" target='blank'> <IoIosArrowDroprightCircle /> The Terraces Resorts </a></li>
          </ul>
        </div>
       
        <div className='footer-info'>
        <h3>Our Link</h3>
         <ul>
         <li><a href="/contact.php"><IoIosArrowDroprightCircle /> Contact</a></li>
          {/* <li><a href=''><IoIosArrowDroprightCircle /> About</a> </li>
          <li><a href=''><IoIosArrowDroprightCircle /> Term & Conditions</a> </li> */}
          <li><a href='https://payment.comfortyourjourney.com/' aria-label="Our Payment Link"><IoIosArrowDroprightCircle /> Payment</a> </li>

         </ul>
        </div>


        <div className='footer-location'>
        <h3>Destinations</h3>
        <ul> 
          {isLoading ? (
                <p> <Loader/></p>
              ) : isError ? (
                <p>Error fetching data</p>
              ) : (
                data.map((itm, key) =>
                  itm.location.status === 1 ? (
                    <li> <a href={`https://www.kanatalresorts.in/${itm.location.location_url}`} key={key}>
                      {itm.location.location}
                    </a></li>
                  ) : null
                )
              )}
           
          </ul>
        </div>
        <div className='footer-contact'>
        <div> 
            <h3>Reach Us</h3>
            <div>
              <div className='footer-contact-icons'>
                <FaPhoneAlt className='icon' />
                <div className='footer-phone'>
                  <a href="tel:8826291111"> +91-8826291111 </a>
                  <a href="tel:8130781111"> +91-8130781111</a>
                </div>
              </div>
            </div>
            <div>

              <div className='footer-contact-icons'>
                <FaEnvelope  className='icon' />
                <div className='footer-mail'>
                  <a href="mailto:info@comfortyourjourney.com"> info@comfortyourjourney.com </a>
                </div>
              </div>
            </div>
            
            <div>
              <div className='footer-contact-icons'>
                <FaLocationDot   className='icon' />
                <div className='footer-map'>
                  <Link to="https://maps.app.goo.gl/jnW9CyibrJLz3HyM8" aria-label="Our Google Map Address"> #308, Third Floor, Suncity Arcade, Sector 54, Gurugram, Haryana 122003 </Link>
                 
                </div>
              </div>
            </div>
         
          </div>
        </div>

      </div>

      <div className='footer-social-section'>
        <div className='social-container'>
          <h5>Stay Connected:</h5>
          <a href="https://www.facebook.com/OfficialCYJ/" aria-label="Facebook Link" target='blank'><FaFacebookF className='social-icon' /></a>
          <a href="https://www.instagram.com/official_cyj/" aria-label="Instagram Link" target='blank'><FaInstagram className='social-icon' /></a>
          <a href="https://twitter.com/Official_CYJ/" aria-label="Twitter Link" target='blank'><FaTwitter   className='social-icon'/></a>
          <a href="https://www.youtube.com/@comfortyourjourney" aria-label="Youtube Link" target='blank'><FaYoutube   className='social-icon'/></a>
        </div>

        <div className='payment-container'>
          <h5>Pay Safely With Us!</h5>
          <img src='../images/payment-1.png' alt='kanatal resorts'></img>

        </div>
      </div>

      <div className='footer-copyright'>
      <p>Copyright &#169;<i  id="copyright-year">  {new Date().getFullYear()}  </i>  |  <a style={ {color:"#fff"}} href='https://www.comfortyourjourney.com/'> Comfort Your Journey</a>  | All Rights Reserved</p>
      </div>

      </div>

    </section>

    </>
  )
}

export default Footer

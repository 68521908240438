import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import './Listingcss/Bannerlocation.css'
import { FaPhoneAlt } from "react-icons/fa";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import {Autoplay} from 'swiper/modules';


function Bannerlocation(props) {
 
 const lok = props.lokglry.map((itm)=>{
    return itm.location.location
  })

return (
    <div className='banner-section-location'>
 <Swiper
       slidesPerView={1}
      loop={true}
      speed={1000}
    
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
      }}   
        modules={[Autoplay ,]}
        className="mySwiper locationbanner"
      >
        {
          props.lokglry.map((z, index1) => (
            z.gallery_images.map((y, index2) => (
              <SwiperSlide key={`slide-${index1}-${index2}`}>
                <img src={y.g_image} alt={`Gallery Image ${index1}-${index2}`} />
              </SwiperSlide>
            ))
          ))
        }
        
        
        <div className='locationbanner-text'>
          <h1   >Hotels & Resorts in {lok}</h1>
        </div>

        
        
      </Swiper>
      <div className='banner-box-img' style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >
          <img src='../icons/people2.png' alt='kanatal resorts'/>
        </div>

        <div className='call-heading'>
            <p>For Booking Call us</p> <a href="tel:8130781111"><FaPhoneAlt className='icon' /> 8130781111</a>
            <a href="tel:8826291111"><FaPhoneAlt className='icon' /> 8826291111 </a>
        </div>
    </div>
  )
}

export default Bannerlocation

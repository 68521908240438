import React from 'react'
import {Link} from 'react-router-dom';
import './Adventurecomponentcss/Adventurebody.css' ;
import { FaPhoneAlt } from "react-icons/fa";

function Adventurebody(props) {
    let tdata = props.thDatas;   
    
    let mydata = tdata.map((itm)=>{
        return itm.things;
    })

  return (
    <>
        <section className='adventurebanner-section' style={{backgroundImage : `url(${props.bnrimgs})`, backgroundSize:'cover',backgroundPosition:'top'}}>
            
            <div className='adventurebanner-text'>
                {/* <h1>{mydata.map(a=>a.heading)}</h1> */}
                {/* <h3>{mydata.map(a=>a.small_description)}</h3> */}
            </div>
        </section>
        <section className='adventure-content' >
            <div className='content-top'>
                <h5>EXPLORE & DISCOVER </h5> 
                <p>Call for Booking 
                <a href="tel:8130781111"><FaPhoneAlt className='icon' /> 8130781111</a>
                <a href="tel:8826291111"><FaPhoneAlt className='icon' /> 8826291111 </a>
                 </p> 
            </div>
            <div className='content-text'>
                {/* <h2>{mydata.map(a=>a.heading)}</h2> */}
                <p dangerouslySetInnerHTML={{ __html: mydata.map(a => a.long_description) }}></p>

            </div>
            <div className='content-image'>
                <img src={mydata.map(a=>a.iconTwo)} alt='background'/>
            </div>
        </section>
    </>
  )
}

export default Adventurebody

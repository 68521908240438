import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import IndexMain from './IndexPage/IndexMain';
import AboutMain from './AboutPage/AboutMain';
import Camplisting from './Camps/Camplisting';
import Weburl from './Weburl';
import ScrollToTop from './Components/ScrollToTop';
import './index.css';
import Contact from './Contactpage/Contact';
import Mainloader from './Components/Mainloder';



function App() {
  return (
  
    <BrowserRouter>
    <Mainloader/>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<IndexMain />} />
        <Route path="/about" element={<AboutMain />} />
        <Route path="/adventure_camps.php" element={<Camplisting />} />
        <Route path="/*" element={<Weburl />} />
        <Route path="/contact.php" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from 'react';
import './Detailcomponentcss/Detailform.css';
import { FaRupeeSign } from "react-icons/fa";
import { usePostEnqsMutation } from '../../Apihandlers/apidata';

const Detailform = (props) => {
  const [postEnqs, { isLoading, isError, isSuccess }] = usePostEnqsMutation();
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const links = window.location.href;
  
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    check_in: '',
    check_out: '',
    adult: 0,
    kids: 0,
    message: '',
    links: links
  });

  const incrementCount = (field, min = 1) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: Math.max(prevState[field] + 1, min)
    }));
  };

  const decrementCount = (field, min = 0) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: Math.max(prevState[field] - 1, min)
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  console.log(formData)

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrors({});

    try {
      await postEnqs(formData).unwrap();
      setMessage('Your message has been sent successfully!');
      setFormData({
        name: '',
        mobile: '',
        email: '',
        check_in: '',
        check_out: '',
        adult: 0,
        kids: 0,
        message: '',
      });
    } catch (error) {
      setMessage(error.data?.msg || 'Error submitting form');
      setErrors(error.data || {});
    }
  };

  const priceDisplays = props.price === null ? 'Call for price' : "₹ " + props.price +"/-**" +`<span>${props.night ?? ''}</span>`  ?? 'Call for price';

  return (
    <div className='detailform-container'>
      <form onSubmit={handleFormSubmit}>
      <h3 dangerouslySetInnerHTML={{__html:priceDisplays}}></h3>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.name}</span>}
        
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.email}</span>}
        
        <input
          type="number"
          name="mobile"
          placeholder="Your Number"
          value={formData.mobile}
          onChange={handleChange}
        />
        {errors.mobile && <span style={{ color: 'red', fontSize: '.8rem' }}>{errors.mobile}</span>}
        
        <div className="check-in-out">
          <div>
            <label htmlFor="check_in">Check-In:</label>
            <input
              type="date"
              id="check_in"
              name="check_in"
              value={formData.check_in}
              onChange={handleChange}
              placeholder="Check-In Date"
            />
          </div>
          <div>
            <label htmlFor="check_out">Check-Out:</label>
            <input
              type="date"
              id="check_out"
              name="check_out"
              value={formData.check_out}
              onChange={handleChange}
              placeholder="Check-Out Date"
            />
          </div>
        </div>

        <div className="form-adult-child">
          <label htmlFor="adults">
            Number of Adults:
            <br />
            <span>12+ Years</span>
          </label>
          <div style={{ display: 'flex', width: '30%' }}>
            <button type="button" onClick={() => decrementCount('adult', 1)}>-</button>
            <input
              type="text"
              id="adults"
              name="adult"
              min={1}
              max={10}
              value={formData.adult}
              readOnly
            />
            <button type="button" onClick={() => incrementCount('adult', 1)}>+</button>
          </div>
        </div>

        <div className="form-adult-child">
          <label htmlFor="children">
            Number of Kids:
            <br />
            <span>6 - 12 Years</span>
          </label>
          <div style={{ display: 'flex', width: '30%' }}>
            <button type="button" onClick={() => decrementCount('kids', 0)}>-</button>
            <input
              type="text"
              id="children"
              name="kids"
              min={0}
              max={10}
              value={formData.kids}
              readOnly
            />
            <button type="button" onClick={() => incrementCount('kids', 0)}>+</button>
          </div>
        </div>

        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
        />

        <button
          type="submit"
          id="submit"
          name="submit"
          className="form-btn semibold"
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Enquiry'}
        </button>

        {message && <div className={`alert ${isSuccess ? 'success' : 'error'}`}>{message}</div>}
      </form>
    </div>
  );
};

export default Detailform;

import React from 'react'
import { Helmet } from 'react-helmet';
const Meta = (props) => {
    const title = props.title;
    const keywords = props.keywords;
    const description = props.description;
    const canonical = props.canonical;
    const noIndex = props.noIndex;
    const ogtittle= props.ogtittle;
    const ogdesc =props.ogdesc;
  return (
    <Helmet>
    <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      <meta property="og:title" content={ogtittle} />
      <meta  property="og:description" content={ogdesc} />
      <link rel="canonical" href={`https://www.kanatalresorts.in/${canonical}`} data-rh="true" />
      {noIndex === "true" ? <meta name="robots" content="NOINDEX, NOFOLLOW" data-rh="true" />: null}
    </Helmet>
  )
}

export default Meta
import React from 'react'


import './Detailcomponentcss/Detailcontent.css'
import Detailabout from './Detailabout'
import Featured from '../../IndexPage/Indexcomponent/Featured'
import Detailform from './Detailform'
import Detailnearby from './Detailnearby'
import Offerindex from '../../IndexPage/Indexcomponent/Offerindex'


function Detailcontent(props) {
let pkdt = [];
props.pkdata.map((item)=>{
  return pkdt = item.resort_camp_data
});

  return (
    <>
    <section className='detail-main-section'>
        <div className='detail-main-container'>
            <div className='detail-main-left'>
                <Detailabout data={props.pkdata}/>
            </div>
            <div className='detail-main-right'>
              <Detailnearby lokId = {pkdt.location_id} />
              <div style={{position:'sticky', top:'2.2rem'}}>
                <Detailform price= {pkdt.price} night={pkdt.nightDay}/>
              </div>
              
            </div>
        </div>
    </section>
    {/* <div className='offerdetailsection' >
      <div className='offerdetailinner'>
       <Offerindex pkId = {pkdt.id} pkUrl={pkdt.package_url} />
       </div>
       </div> */}
   
    <Featured /> 
    </>
  )
}

export default Detailcontent

import React from 'react';
// Import Swiper React components
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useGetOffersQuery } from '../../Apihandlers/apidata';
import { useGetPackageQuery } from '../../Apihandlers/apidata';
import Loader from '../../Components/Loader'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './indexcss/Offerindex.css';

// import required modules
import {Autoplay} from 'swiper/modules';

const Offerindex = (props) => {
  const pkid = props.pkId;
  const { data: ofrData, isError: ofrError, isLoading: ofrLoading } = useGetOffersQuery();
  const { data: ofrpDatas, isError: ofrpError, isLoading: ofrpLoading } = useGetPackageQuery();

  if (ofrError) {
    return <div>Error loading data...</div>;
  }

  if (ofrLoading) {
    return <div> </div>;
  }
  if (ofrpError) {
    return <div>Error loading data...</div>;
  }

  if (ofrpLoading) {
    return <div> <Loader/></div>;
  }
  console.log("abv", ofrData)
 
  return (
    <>
      <Swiper
       slidesPerView={1}
      spaceBetween={20}
      
      loop={true}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
        
      }}  
      speed={2000}
       
        modules={[Autoplay]}
        className="mySwiper offersection"
      >
        {ofrData.map((itm, key) => (
          itm.package_url != null ?
          <SwiperSlide key={key}>
            <Link to={itm.package_url}><img src={itm.offers_image} alt={itm.heading} /></Link>
          </SwiperSlide>
          : null
        ))}

      </Swiper>
    </>
  );
}

export default Offerindex;
import React from 'react'
import Indexbanner from './Indexcomponent/Indexbanner'
import Offerindex from './Indexcomponent/Offerindex'
import Iconadventure from './Indexcomponent/Iconadventure'
import Featured from './Indexcomponent/Featured'
import Whyus from './Indexcomponent/Whyus'
import Indexadventure from './Indexcomponent/Indexadventure'
import Location1featured from './Indexcomponent/Location1featured'
// import Bannerpoint from './Indexcomponent/Bannerpoint'
import Location2featured from './Indexcomponent/Location2featured'
import Nearby from './Indexcomponent/Nearby'
import Indextestandform from './Indexcomponent/Indextestandform'
import Otherweb from './Indexcomponent/Otherweb'

import Meta from '../Components/Meta';
import Mainloader from '../Components/Mainloder'



const IndexMain = () => {



  return (
    <>
    <Meta ogtittle="Kanatal Resorts - Luxury Resorts, Hotels, Adventure Camps Near Delhi" ogdesc="Call us at 8130781111 for Luxury Resorts, Hotels, Adventure Camps & Activities in Kanatal, Mussoorie, Rishikesh, Dhanaulti, etc.. Also, we offer river rafting, jungle camps, igloo camps, and much more. Book Now with CYJ" title="Kanatal | Mussoorie | Rishikesh | Dhanaulti | Haridwar, etc. - CYJ" keywords="kanatal resorts, resorts in kanatal, luxury resorts in kanatal, adventure camps in kanatal, kanatal adventure camps, raiver rafting in rishikesh, adventre camps in rishikesh, rishikesh camps, adventure camps in dhanaulti, resorts in mussoorie, luxury resorts in mussoorie" description="Call us at 8130781111 for Luxury Resorts, Hotels, Adventure Camps & Activities in Kanatal, Mussoorie, Rishikesh, Dhanaulti, etc.. Also, we offer river rafting, jungle camps, igloo camps, and much more. Book Now with CYJ" canonical="canonical" noIndex="false"/>
    <div style={{backgroundImage : 'linear-gradient(rgb(0, 0, 0) 50%, #004225 95%, rgb(255 255 255)100%)'}} >
      <Indexbanner />
      <Featured />
      <Location1featured/>
      <Offerindex/>
      <Otherweb/>
      {/* <Location2featured/> */}
      <Iconadventure/>
      <Whyus/>
      <Indexadventure/>
      {/* <Bannerpoint/> */}
      <Nearby/>
      <Indextestandform/>
     
     
    </div>
    
    </>
  )
}

export default IndexMain

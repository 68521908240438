import React, { useState, useEffect } from 'react';
import './css/Mainloader.css'; // Import the CSS file for styling

const Mainloader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Set a timer to hide the loader after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
<div className={`main-loader ${!isVisible ? 'exit-animation' : ''}`}>
{/* Add your loader content here */}
<div id="loadercube"></div>
</div>
);
};

export default Mainloader;

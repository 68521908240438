import React from 'react'
import Campbanner from './Camplistingcomponent/Campbanner'

import Indexadventure from '../IndexPage/Indexcomponent/Indexadventure'
import Camplist from '../Components/Camplist'
import { useGetPackageQuery } from '../Apihandlers/apidata';
import Loader from '../Components/Loader';
import Meta from '../Components/Meta';
import Mainloader from '../Components/Mainloder';
function Camplisting() {

  const { data: pkgdata, isError: pkgError, isLoading: pkgLoading } = useGetPackageQuery();
  if (pkgError) {
    return <div>Error loading data...</div>; 
}

if (pkgLoading) {
    return <div> <Loader/></div>; 
}

let campsData=[];
if(pkgdata){
  campsData = pkgdata.filter((c)=> c.resort_camp_data.category == 'Camps');
}

return (
<>
{ <Meta ogtittle="Adventure Camps Near Delhi | Book Adventure Camps Packages @8826291111" ogdesc="Grab the Best Deals on Adventure Camps in Kanatal, Dhanaulti, Rishikesh, etc. We offer the perfect place for adventure and nature lovers both for camping at best price. Book@8130781111" title="Adventure Camps Near Delhi | Book Adventure Camps Packages @8826291111" keywords="Best Adventure Camps Packages, Camps in kanatal" description="Grab the Best Deals on Adventure Camps in Kanatal, Dhanaulti, Rishikesh, etc. We offer the perfect place for adventure and nature lovers both for camping at best price. Book@8130781111" canonical={'https://www.kanatalresorts.in/adventure_camps.php'} noIndex="false"/>}
<div style={{backgroundImage : 'linear-gradient(rgb(0, 0, 0) 25%, #004225 85%, rgb(255 255 255)100%)', marginBottom:'5rem'}} >
        <Campbanner/>
        <Camplist packagedata={campsData} />
        <Indexadventure/>
    </div>

</>

  )
}

export default Camplisting

import React from 'react'
import './Camplistingcss/Campbanner.css'

function Campbanner() {
  return (
    <>
    <section className='campbanner-section' style= {{backgroundImage:'url(../images/campbanner.jpg)'}} >
        <div className='campbanner-image' style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}>
            <img src='../icons/people1.png' alt='kanatal resorts'/>
        </div>
        <div className='campbanner-text'>
            <div className='upper'>
                <h1 style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >Find Your Bliss With Luxury Camping</h1>
                <p>CYJ offers a unique luxury camping experience that combines the thrill of outdoor adventure with the comfort of modern amenities. Our campsites are located in breathtaking natural settings and feature spacious tents equipped with plush bedding, en-suite bathrooms, and climate control. Guests enjoy gourmet dining prepared by skilled chefs using fresh, local ingredients, with meals served under the stars or in stylish dining tents.<br/><br/>
                We provide a range of curated activities, such as guided nature walks, yoga sessions, and stargazing, designed to connect guests with nature and local culture. Our dedicated team ensures personalized service, catering to every guest's needs and preferences. Committed to sustainability, we implement eco-friendly practices to preserve the environment for future generations.<br/><br/>
                CYJ's luxury camping offers an exceptional blend of comfort, adventure and exploration, making it the perfect escape for discerning travelers seeking a memorable outdoor experience.
 </p>
            </div>
            <div className='lower'>
                <div className='content'>
                    <img src='../icons/best-seller.png' alt='kanatal resorts'/>
                    <div>
                     <h3> Best Camping</h3> 
                     {/* <p> Lorem ipsum dolor sit.</p> */}
                    </div>
                </div>
                <div className='content'>
                    <img src='../icons/affordable.png' alt='kanatal resorts'/>
                    <div>
                     <h3> Affordable Prices</h3> 
                     {/* <p> Lorem ipsum dolor sit.</p> */}
                    </div>
                </div>
            </div>
        </div>

        

    </section>
      
    </>
  )
}

export default Campbanner

import React, { useEffect } from 'react';
import './indexcss/Indexbanner.css';
import { Link } from 'react-router-dom';
import { useGetLocsQuery } from '../../Apihandlers/apidata';
import Loader from '../../Components/Loader';

const Indexbanner = () => {
    const { data, isError, isLoading } = useGetLocsQuery();

    useEffect(() => {
        // Step 1: Get DOM elements
        const nextDom = document.getElementById('next');
        const prevDom = document.getElementById('prev');
        const carouselDom = document.querySelector('.carousel');
        const SliderDom = carouselDom.querySelector('.carousel .list');
        const thumbnailBorderDom = document.querySelector('.carousel .thumbnail');
        const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');

        if (thumbnailItemsDom.length > 0) {
            thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        }

        const timeRunning = 500;
        const timeAutoNext = 10000;

        let runTimeOut;
        let runNextAuto = setTimeout(() => {
            nextDom.click();
        }, timeAutoNext);

        const showSlider = (type) => {
            const SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');
            const thumbnailItemsDom = document.querySelectorAll('.carousel .thumbnail .item');

            if (type === 'next') {
                SliderDom.appendChild(SliderItemsDom[0]);
                thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
                carouselDom.classList.add('next');
            } else {
                SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
                thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
                carouselDom.classList.add('prev');
            }

            clearTimeout(runTimeOut);
            runTimeOut = setTimeout(() => {
                carouselDom.classList.remove('next');
                carouselDom.classList.remove('prev');
            }, timeRunning);

            clearTimeout(runNextAuto);
            runNextAuto = setTimeout(() => {
                nextDom.click();
            }, timeAutoNext);
        };

        nextDom.onclick = () => {
            showSlider('next');
        };

        prevDom.onclick = () => {
            showSlider('prev');
        };

        return () => {
            clearTimeout(runTimeOut);
            clearTimeout(runNextAuto);
        };
    }, [0]);

    return (
        <div>
            <div className="carousel">
                <div className="list">
                    {isLoading ? (
                        <Loader/>
                    ) : isError ? (
                        <p>Error fetching data</p>
                    ) : (
                        data.map((itm, key) => (
                            itm.location.status== 1 ?
                            <div className="item" key={key}>
                                <img src={itm.banner_image} alt={`Banner ${key}`} />
                                <div className="content">
                                    <div className="title">{itm.location.location}</div>
                                    <div className="des">{itm.location.small_description}</div>
                                    <div className="buttons">
                                        <a href={itm.location.location_url}>see more</a>
                                        <a href="tel:8130781111">contact</a>
                                    </div>
                                </div>
                            </div>
                            : null
                        ))
                    )}
                </div>

                <div className="thumbnail">
                    {isLoading ? (
                        <p> </p>
                    ) : isError ? (
                        <p>Error fetching data</p>
                    ) : (
                        data.map((itm, key) => (
                            itm.location.status == 1 ?
                            <a href={itm.location.location_url} className="item" key={key}>
                                <img src={itm.banner_image} alt={`Thumbnail ${key}`} />
                                <div className="content">
                                    <div className="title">{itm.location.location}</div>
                                </div>
                            </a>
                            :null
                        ))
                    )}
                </div>

                <div className="arrows">
                    <button id="prev">
                        <span className="material-symbols-outlined">arrow_back</span>
                    </button>
                    <button id="next">
                        <span className="material-symbols-outlined">arrow_forward</span>
                    </button>
                </div>

                <img className="bush-banner" src="./images/bush.png" alt="Bush Banner" />
            </div>
        </div>
    );
};

export default Indexbanner;

import React from 'react';
import Bannerlocation from './Listingcomponent/Bannerlocation';
import Nearbylocation from './Listingcomponent/Nearbylocation';
import Contentbanner from './Listingcomponent/Contentbanner';
import Camplist from '../Components/Camplist';
import { useGetLocsUrlQuery } from '../Apihandlers/apidata';
import { useGetPackageQuery } from '../Apihandlers/apidata';
import Loader from '../Components/Loader';
import Meta from '../Components/Meta';

function Locationlisting(props) {
  const { data: lData, isError: lError, isLoading: lLoading } = useGetLocsUrlQuery(props.slugUrl);
  const { data: pkgLdata, isError: pkgLError, isLoading: pkgLLoading } = useGetPackageQuery();

if (lError) {
    return <div>Error loading data...</div>; 
}

if (lLoading) {
    return   <Loader/>
}
  
  let locationIds = [];
  if (lData) {
    lData.map((itm) => {
      locationIds.push(itm.location.id);
    });
  }

  

  let pkgdata = [];
  if (pkgLdata) {
    pkgdata = pkgLdata.filter((pkg) => locationIds.includes(pkg.resort_camp_data.location_id));
  }

  if (pkgLError) {
    return <div>Error loading data...</div>; 
}

if (pkgLLoading) {
    return <div> <Loader/></div>;
}

const metaTags = lData.map((itm)=>{
 return <Meta ogtittle={itm.location.OgMetaTitle} ogdesc={itm.location.OgMetaDescription} title={itm.location.metaTitle} keywords={itm.location.metaKeyWords} description={itm.location.metaDescription} canonical={'https://www.kanatalresorts.in/'+itm.location.location_url} noIndex="false"/>
})

  return (
    <>
    
    {metaTags}
    <div style={{backgroundImage: 'linear-gradient(rgb(0, 0, 0) 15%, #004225 85%, rgb(255 255 255)100%)'}}>
  
      <Bannerlocation lokglry={lData} />
      <div className='location-list'>
        <Camplist packagedata={pkgdata} />
      </div>
      <Contentbanner lData ={lData} />
      <Nearbylocation lokdta = {locationIds} />
    </div>
   
    </>
  );
}

export default Locationlisting;

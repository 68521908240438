import React from 'react'
import {Link} from 'react-router-dom';
import './Listingcss/Contentbanner.css'



function Contentbanner(props) {


  const banner = props.lData.map((itm)=>(itm.banner_image))
  return (
    
     
    <div style= {{backgroundImage:`url(${banner})` ,  backgroundPosition: 'bottom' , backgroundAttachment:'fixed'}} >
      
      <section className='contentbanner-section' >
        <div className='contentbanner-text'>
        
            {
            props.lData.map((itm)=>(
             <>
              <h3>{itm.location.location}</h3>
              <p dangerouslySetInnerHTML={{__html:itm.location.long_description}}></p>
              </>
            ))
          }  
           
        </div>
      </section>
    </div>
  )
}

export default Contentbanner

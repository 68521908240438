
import React, { useEffect, useState } from 'react'
import './css/Camplist.css'
import {Link} from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { IoIosStar } from "react-icons/io";
import 'swiper/css/navigation';
import { ImLocation } from "react-icons/im";
import { Autoplay, Navigation } from 'swiper/modules';
import Loader from '../Components/Loader'
import { useGetLocsQuery } from '../Apihandlers/apidata';
function Camplist(props) {

  
  const packagedata= props.packagedata;
  const { data: pkglok, isError: pkglokError, isLoading: pkglokLoading } = useGetLocsQuery();

  if(packagedata.length === 0){
    return <h2 style={{color:'#fff', textAlign: 'center', marginBottom:'2rem'}}>Coming Soon</h2>
  }

  if(packagedata.length === ""){
    return <h2 style={{color:'#fff', textAlign: 'center', marginBottom:'2rem'}}><Loader/></h2>
  }


  let packageList = [];


  packagedata.forEach((p) => {
    pkglok.forEach((l) => {
      console.log("id", l.location.id)
      if (p.resort_camp_data.location_id === l.location.id) {
    
        const newItem = {
          "heading": p.resort_camp_data.heading,
          "location": l.location.location,
          "pkgLink": p.resort_camp_data.package_url,
          "small_description": p.resort_camp_data.small_description,
          "price": p.resort_camp_data.price,
          "banner_image": p.banner_image,
          "rating": p.resort_camp_data.rating,
          "status":p.resort_camp_data.status,
          "nightDay": p.resort_camp_data.nightDay,
          "gallery_image": p.gallery_image
        };
        const exists = packageList.some(item => item.pkgLink === newItem.pkgLink);
        if (!exists) {
          packageList.push(newItem);
        }
      }
    });
  });
  
  return (
    <>
      <section className='list-section'>
     
          <div className='grid-main-container'>

            {
              
              packageList.map((x, y)=>(      
               x.status === 1 ?
            <div className='grid-container' key={y}>
               <div className='grid-image'> 

                  <Swiper
                          slidesPerView='1'
                          
                          loop={true}
                          navigation={true}
                          centeredSlides={true}
                          autoplay={{
                          delay: 3500,
                          
                        }}   
                          modules={[Autoplay, Navigation]}
                          className="mySwiper grid-image-slider"
                          
                        >
                        
                          {
                            x.gallery_image.map((itm, key)=>(
                              <SwiperSlide className='grid-image-slide' key={key}>
                                  <img src={itm} alt={x.heading}></img>  
                                 
                              </SwiperSlide>
                              
                            ))
                          }
                  </Swiper>
                  { x.rating == null ? '' :  <div className='grid-image-rating'  style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}}>
                                    {Array.from({ length: x.rating }, (_, index) => (
                                        <IoIosStar key={index}  className='star-rating' />
                                    ))}
                  </div>}
               </div>
               <div className='grid-text'>
                   <h3 style= {{backgroundImage:'url(../images/bg-sticker-font.jpg)'}} >{x.heading}</h3>
                   <h6><ImLocation /> {x.location}</h6>
                   <p>{x.small_description}</p>
                   {/* <div className='amini-link'> */}
                       <div className='aminities-list'>
                       {/* {
                          [...x.amenities] 
                            .sort(() => Math.random() - 0.5) 
                            .slice(0, 5) 
                            .map((a, key) => (
                              <div className='aminity-grid'>
                              <img src={a.amnityImage} key={key} alt={`Amenity ${key}`} />
                              <span>{a.amenitiesHeading}</span>
                              </div>
                            ))
                        } */}
                       </div>
                       <div className='price-link-grid custom-btn btn-7'>
                          <div className='price-room-grid' ><h5>{x.price ? `INR ${x.price}/-**` : 'Call for Price'}</h5>
                          <span>{x.nightDay ? `${x.nightDay}` : ''}</span></div>
                          <a href={`../${x.pkgLink}`} className='linkbutton '> 
                          <span>View Detail</span>
                          </a>
                        </div>
                   {/* </div> */}
               </div>
               {/* <div className='floting-image'> <img src={x.flotingimage}/></div> */}
            </div>
            
             : null )  
             )}
          </div>
          
      </section>
    </>
  )
}

export default Camplist

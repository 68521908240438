import React from 'react'
import './Detailcomponentcss/Detailabout.css'
import Detailgallery from './Detailgallery'


function Detailabout(props) {
let pkdt = [];
props.data.map((item)=>{
  return pkdt = item.resort_camp_data
});

let pkamity = [];
props.data.forEach(item => {
    pkamity = pkamity.concat(item.amenities);
});


let gallery = [];
props.data.forEach(item => {
  gallery = item.gallery_image;
});

  return (
    <>
      <section className='detail-about-section'>
        <div className='detail-about-container'>
            <h4>{pkdt.heading}</h4>
            {/* <h2>The Terraces Resort Kanatal</h2> */}
            {/* <div className='detailamenities'>
            {
            pkamity.map((itm, key) => (
                <div key={key}>
                    <img src={itm.amnityImage} alt={itm.amenitiesHeading} />
                    <span>{itm.amenitiesHeading}</span>
                </div>
            ))
        }               
            </div> */}
            <p dangerouslySetInnerHTML={{ __html: pkdt.long_description }} />
            <Detailgallery gallery={gallery}/>
            
        </div>
      </section>
    </>
  )
}

export default Detailabout

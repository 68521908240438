import React from 'react'
import Detailbanner from './Detailcomponents/Detailbanner'
import Detailabout from './Detailcomponents/Detailabout'
import Detailcontent from './Detailcomponents/Detailcontent'
import Detailform from './Detailcomponents/Detailform'
import { useGetPackageUrlQuery } from '../Apihandlers/apidata'
import Loader from '../Components/Loader'
import Meta from '../Components/Meta';
import Mainloader from '../Components/Mainloder'
function Detailpage(props) {
  const urlfetch = props.slugUrl;
 
  const {data: pkdata, isError: pkError, isLoading: pkLoading} = useGetPackageUrlQuery(urlfetch);
  if(pkError){
    return <div>Error loading data...</div>; 
  }
  if(pkLoading){
    return <div> <Loader/> </div>;
  }

  let pkdta = [];
  pkdata.map((item)=>{
    return pkdta = item.resort_camp_data
  });

  let pkBanner = [];
  pkdata.map((item)=>{
    return pkBanner = item.banner_image;
  });

  const metaTags = pkdata.map((itm)=>{
    return <Meta ogtittle={itm.resort_camp_data.OgMetaTitle} ogdesc={itm.resort_camp_data.OgMetaDescription} title={itm.resort_camp_data.metaTitle} keywords={itm.resort_camp_data.metaKeywords} description={itm.resort_camp_data.metaDescription} canonical={'https://www.kanatalresorts.in/'+itm.resort_camp_data.package_url} noIndex="false"/>
   });

  return (
    <>
    {metaTags}
    <div style={{backgroundImage : 'linear-gradient(rgb(0, 0, 0) 10%, #004225 85%, rgb(255 255 255)100%)'}} >
        <Detailbanner pkdta={pkdta} pkBanner={pkBanner}/>
        <Detailcontent pkdata={pkdata}/>
        {/* <div id='popupform'>
          <Detailform/>
        </div> */}
      
    </div>

    </>
  )
}

export default Detailpage

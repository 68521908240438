import React from 'react'
import {Link} from 'react-router-dom';
import Loader from '../../Components/Loader'
import './indexcss/Nearby.css';
import { FaLocationDot } from "react-icons/fa6";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import {Autoplay, Navigation} from 'swiper/modules';
import { useGetNearByLocationQuery } from '../../Apihandlers/apidata';
function Nearby() {

    const {data: nrbydata, isError: nrbyError, isLoading: nrbyLoading} = useGetNearByLocationQuery();
    if(nrbyError){
      return <div>Error loading data...</div>; 
    }
    if(nrbyLoading){
      return <div> </div>;
    }

  return (
    <>
      <section className='nearby-attract-section'>
        <p>Explore</p>
        <h2>Nearby Attraction</h2>
        <h3>Enjoy a quick escape to the most fascinating places & get a whiff of the history in hills.</h3>
        <div className='near-attract-inner'>


        <Swiper
           
            spaceBetween={20}
            loop={true}
            navigation={true}
            autoplay={{
            delay: 3400,
            disableOnInteraction: false,
          }}   
          breakpoints={{
            
            1200: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          
            900: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            540: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper nearby-slider"
          >
            {
                nrbydata.map((itm, key) => (
                  itm.status == 1 ?
                    <SwiperSlide key={key}>
                        <div className='nearby-container'>
                            <div className='nearby-image'>
                                <img src={itm.vertical_image} alt={itm.heading} /> {/* Added alt attribute */}
                            </div>
                            <div className='nearby-text'>
                                <h3>{itm.heading}</h3>
                                <h5><FaLocationDot className='icon' />{itm.sub_location}</h5>
                                <p>{itm.small_description}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    : null
                ))
            }

                                  

            </Swiper>

        </div>
      </section>
    </>
  )
}

export default Nearby

import React from 'react'

import './indexcss/Whyus.css'

function Whyus() {
  return (
    <>
      <section className='whyus-section' 
      // style={{ backgroundImage:'url(../icons/funfact_img.png)',}}
      >
        <h5>We Are Awesome</h5>
        <h2>Why Choose CYJ</h2>
        <div className='whyus-conatiner'>
            <div className='whyus-image'>
              <img src='./icons/funfact_img.png' alt='kanatal resorts'></img>
            </div>
            <div className='whyus-left'>
                <div className='whyus-icon'>
                    <img src='./icons/travel (1).png' alt='kanatal resorts'></img>
                    <h4>Diverse Destinations</h4>
                    <p>Explore a world of possibilities with our wide range of unique and exciting travel destinations.</p>
                </div>
                <div className='whyus-icon'>
                    <img src='./icons/value-proposal.png' alt='kanatal resorts'></img>
                    <h4>Value for Money</h4>
                    <p>Enjoy unforgettable experiences at unbeatable prices with our value-for-money travel packages.</p>
                </div>
                <div className='whyus-icon'>
                    <img src='./icons/location-pin.png' alt='kanatal resorts'></img>
                    <h4>Beautiful Places</h4>
                    <p>Discover the world's most beautiful places and create lasting memories on every journey.</p>
                </div>
            </div>
            
            <div className='whyus-right'>
                <div className='whyus-icon'>
                <img src='./icons/booking.png' alt='kanatal resorts'></img>
                    <h4>Fast Booking</h4>
                    <p>Experience seamless travel planning with our quick and easy booking process.</p>
                </div>
                <div className='whyus-icon'>
                    <img src='./icons/public-service.png' alt='kanatal resorts'></img>
                    <h4>Support Team</h4>
                    <p>Travel with confidence knowing our dedicated support team is available 24/7 to assist you.</p>
                </div>
                <div className='whyus-icon'>
                    <img src='./icons/travel.png' alt='kanatal resorts'></img>
                    <h4>Passionate Travel</h4>
                    <p>Let our passion for travel ignite your own as we guide you on your next adventure.</p>
                </div>
            </div>

        </div>
      </section>
    </>
  )
}

export default Whyus

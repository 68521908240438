import React from 'react'
import { Link } from 'react-router-dom';
import './indexcss/Indexadventure.css'
import Loader from '../../Components/Loader'
import { useGetThingsQuery } from '../../Apihandlers/apidata';

const Indexadventure = () => {

const {data: thingData, isError: thingError, isLoading: thingLoading} = useGetThingsQuery();

if (thingError) {
    return <div>Error loading data...</div>;
  }

  if (thingLoading) {
    return <div> </div>;
  }

  return (
    <>
    <section className='adventure-section'>
        <p>Top Adeventures</p>
        <h2>Trending Adevntures</h2>
        <div className='adventure-container'>
            {
                thingData.map((itm, key)=>(
                  <a 
                  key={key}
                  href={'../'+itm.things.things_url} className={`adventureall grid-adventure${key}`} style={{ backgroundImage: `url(${itm.banner_image})` }} >
                    <h3>{itm.things.things}</h3>
                      <div className='adventure-text'>
                             <p> {itm.things.small_description}</p>
                          <div className='shine' ></div>
                      </div>  
                  </a>
                ))
            }
                      

        </div>
    </section> 
    </>
  )
}

export default Indexadventure

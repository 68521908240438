import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const apidata = createApi({

    reducerPath: 'apidata',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://kanatal.corporateeventplanner.in/api' }),
    endpoints: (builder) => ({

      getPages: builder.query({
        query: () => 'page',
      }),

      getLocs: builder.query({
        query: () => 'location',
      }),

      getLocsUrl: builder.query({
        query: (lurl) => 'location/'+lurl,
      }),

      getThings: builder.query({
        query: () => 'things-to-do',
      }),

      getNearByLocation: builder.query({
        query: () => 'near-by-atraction',
      }),

      getPackage: builder.query({
        query: () => 'package',
      }),

      getPackageUrl: builder.query({
        query: (url) => 'package/'+url,
      }),

      getAllUrl: builder.query({
        query: () => 'all-slug',
      }),

      getUrl: builder.query({
        query: (slug) => 'all-slug/'+slug,
      }),

      getOffers: builder.query({
        query: ()=>'offers',
      }),

      postEnqs: builder.mutation({
        query: (formDatas) => {
          return {
            url: 'lead-insert',
            method: 'POST',
            body: formDatas
          };
        }
      }),

    }),
  });
  
  export const {useGetPagesQuery , useGetLocsQuery, useGetLocsUrlQuery, useGetThingsQuery, useGetPackageQuery, useGetPackageUrlQuery, useGetAllUrlQuery, useGetUrlQuery, useGetNearByLocationQuery, useGetOffersQuery, usePostEnqsMutation} = apidata
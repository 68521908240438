import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUrlQuery } from './Apihandlers/apidata';
import Locationlisting from './Locationlisting/Locationlisting';
import Detailpage from './Detailpage/Detailpage';
import Adventure from './Adventure/Adventure';
import Loader from '../src/Components/Loader'
import Error from './Error';
const Weburl = () => {
  
    const location = useLocation();
    const finalPath = location.pathname.slice(1);
    const navigate = useNavigate();

    const { data: urls, isError: slugError, isLoading: slugLoading } = useGetUrlQuery(finalPath);
    
    if (slugError) {
        return <div>Error loading data...</div>; 
    }

    if (slugLoading || !urls) {
        return <div> <Loader/></div>;
    }
    if(urls.length === 0){
      return <Error />
    }
    return (
        <div>
            {urls.map((item, index) => {
                switch (item.table_name) {
                    case 'location':
                        return <Locationlisting key={index} slugUrl={item.table_url} />;
                    case 'package':
                        return <Detailpage key={index} slugUrl={item.table_url} />;
                    case 'thingstodo':
                        return <Adventure key={index} slugUrl={item.table_url}/>;
                    default:
                        return <Error key={index}/>;
                }
            })}
        </div>
    );
};

export default Weburl;

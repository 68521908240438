import React, { useEffect, useState } from 'react';
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'


function Detailgallery(props) {

  const [galleryKey, setGalleryKey] = useState(0); 
  useEffect(() => {
    initLightboxJS("Insert License key", "Insert plan type here");
  },[]);

  useEffect(() => {
    setGalleryKey(prevKey => prevKey + 1);
  }, [props.gallery]);

  return (
    <div key={galleryKey} className='detailgallerycontainer' style={{marginTop:'2rem'}}>
    <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto detailgalleryimage'   showThumbnails={true}>
    {props.gallery.map((x,y)=>(
  <img className='w-full rounded galleryimage' src={x } alt='kanatal resorts'/>))}
     </SlideshowLightbox> 
        </div>
      )
    }
  


export default Detailgallery
